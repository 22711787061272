/* Name : Rudraraju, DileepVarma | Student ID: 1002057318 */
/* Name : Boggavarapu Divya | Student Id : 1002086719 */
/* Name : Arepally Rohith | Student Id: 1002156380 */
/* Name : Potluri Shravani | Student Id : 1002129834 */
/* Name : Ram Shriyukta | Student Id : 1002152017 */
/* Font Size : 12px, Font Color : red, white, #2a9d8f  */
.login-container {
    max-width: 400px;
    margin: 50px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.login-container h2 {
    text-align: center;
    margin-bottom: 30px;
}

.form-group {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.form-group label {
    flex: 1;
    margin-bottom: 5px;
}

.form-group input {
    flex: 2;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.error {
    color: red;
    font-size: 0.8em;
}

.form-actions {
    text-align: center;
    padding-top: 5px !important;
}

.form-actions button {
    width: 100%;
    padding: 10px;
    border: none;
    background-color: #005a8d;
    color: white;
    border-radius: 4px;
    cursor: pointer;
}

.form-actions button:hover {
    background-color: #005a8d;
}

.forgot-password,
.signup {
    margin-top: 10px;
}

.forgot-password a,
.signup a {
    color: #2a9d8f;
    text-decoration: none;
}

.forgot-password a:hover,
.signup a:hover {
    text-decoration: underline;
}
