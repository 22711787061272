/* Name : Rudraraju, DileepVarma | Student ID: 1002057318 */
/* Name : Boggavarapu Divya | Student Id : 1002086719 */
/* Name : Arepally Rohith | Student Id: 1002156380 */
/* Name : Potluri Shravani | Student Id : 1002129834 */
/* Name : Ram Shriyukta | Student Id : 1002152017 */
/* Font Size : 16px, Font Color : #005a8d,   */
.about-container {
    padding: 20px;
    padding-top: 60px;
    background-color: #f5f5f5;
}

.about-title {
    text-align: center;
    color: #005a8d;
    margin-bottom: 30px;
}

.about-content {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 10px;
    padding: 20px;
    width: 60%;
    min-width: 300px;
    margin: auto;
}

.about-content p {
    margin-bottom: 15px;
    font-size: 16px;
}