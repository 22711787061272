/* Name : Potluri Shravani */
/* Student Id : 1002129834 */
/* Font Size : 16px, 18px, Font Color : #005a8d, #333, #6c757d, White  */
.profile-container {
    max-width: 800px;
    margin: 40px auto;
    padding: 20px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.profile-container h2 {
    text-align: center;
    color: #005a8d;
    margin-bottom: 30px;
}

.form-group {
    margin-bottom: 20px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    color: #333;
    font-weight: 600;
}

.form-group input {
    width: 100%;
    /* Full width */
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
}

.form-group input[type="email"][disabled] {
    background: #e9ecef;
    color: #6c757d;
}

.form-actions {
    text-align: center;
    padding-top: 20px;
}

.form-actions button {
    padding: 10px 20px;
    color: white;
    background-color: #005a8d;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 18px;
    transition: background-color 0.3s ease;
}

.form-actions button:hover {
    background-color: #003f63;
}

@media (max-width: 768px) {
    .profile-container {
        width: 90%;
        margin: 20px auto;
    }

    .form-actions button {
        width: 100%;
    }
}