/* Name : Arepally Rohith */
/* Student Id: 1002156380 */
/* Font Size: 16px, 24px,  Font Color: #333, #555, white */

.medication-dispensation-container {
    padding: 20px;
    background: #f5f5f5;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    max-width: 1000px;
    margin: 20px auto;
}

.medication-dispensation-container h2 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
    font-size: 1.5em;
}

.medication-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.medication-item {
    background: #ffffff;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 15px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.medication-item span {
    display: block;
    color: #333;
    font-size: 1em;
    margin-bottom: 5px;
}

.status-actions {
    display: flex;
    align-items: center;
}

.status-actions span {
    margin-right: 10px;
    font-weight: bold;
    color: #555;
}

button {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
    text-transform: uppercase;
    transition: background-color 0.3s ease;
}

button:hover {
    opacity: 0.8;
}

button:disabled {
    background: #ccc;
    cursor: not-allowed;
}

.dispense-button {
    background-color: #28a745;
    color: white;
}

.dispense-button:hover {
    background-color: #218838;
}

.dispense-button:disabled {
    background: #ccc;
}
/* .medication-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.medication-name, .patient-name, .status-actions span {
    flex: 1;
    text-align: center;
    margin-right: 1em; 
}

.status-actions span:last-child {
    margin-right: 0;
} */
.prescriptions-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 20px;
    width: 100%;
    max-width: 1000px;
    margin: 20px auto;
}

/* For screens smaller than 600px */
@media (max-width: 600px) {
    .prescriptions-grid {
        grid-template-columns: 1fr;
    }
}

/* For screens between 600px and 900px */
@media (min-width: 600px) and (max-width: 900px) {
    .prescriptions-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

/* For screens larger than 900px */
@media (min-width: 900px) {
    .prescriptions-grid {
        grid-template-columns: repeat(3, 1fr);
    }
}

.prescription-card {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0px 10px 20px -10px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease-in-out;
    min-width: 250px;
    text-align: left;
}

.prescription-card:hover {
    transform: scale(1.03);
}

.medication-name {
    color: #005a8d;
    margin-bottom: 10px;
}

.prescription-info p {
    margin: 5px 0;
}

.prescription-actions {
    margin-top: 20px;
    text-align: center;
}