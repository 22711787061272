/* Name: Rudraraju, DileepVarma | Student ID: 1002057318 */
/* Font color - #005a8d */
.report-generation-container {
    padding: 20px;
    background-color: #f8f8f8;
    border-radius: 8px;
    max-width: 800px;
    margin: auto;
    color: #005a8d; /* Sets the default text color for the container */
}

.report-generation-container h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #005a8d;
}

.reports-list {
    display: flex;
    flex-direction: column;
    color: #005a8d; /* Ensures text in this container is the specified color */
}

.report-date {
    color: #005a8d; /* Specific style to set the color of the date */
}
