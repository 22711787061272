/* Name : Rudraraju, DileepVarma | Student ID: 1002057318 */
/* Name : Boggavarapu Divya | Student Id : 1002086719 */
/* Name : Arepally Rohith | Student Id: 1002156380 */
/* Name : Potluri Shravani | Student Id : 1002129834 */
/* Name : Ram Shriyukta | Student Id : 1002152017 */
/* Font Color : #005a8d, #666666, #ffffff */
.contact-container {
    padding-top: 10px;
    padding: 20px;
    background-color: #f5f5f5;
    text-align: center;
}

.contact-title {
    padding-top: 40px;
    color: #005a8d;
    margin-bottom: 10px;
}

.contact-description {
    color: #666666;
    margin-bottom: 20px;
}

.contact-form {
    max-width: 500px;
    margin: auto;
    background-color: #ffffff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.contact-form input,
.contact-form textarea {
    width: 100%;
    padding: 10px 0px 10px 10px;
    margin-bottom: 15px;
    border: 1px solid #cccccc;
    border-radius: 5px;
}

.contact-form textarea {
    resize: vertical;
    min-height: 100px;
}

.submit-button {
    background-color: #005a8d;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.submit-button:hover {
    background-color: #0056b3;
}