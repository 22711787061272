/* Name : Rudraraju, DileepVarma | Student ID: 1002057318 */
/* Name : Boggavarapu Divya | Student Id : 1002086719 */
/* Name : Arepally Rohith | Student Id: 1002156380 */
/* Name : Potluri Shravani | Student Id : 1002129834 */
/* Name : Ram Shriyukta | Student Id : 1002152017 */
/* Font Size : 14px, Font Color : #555 */
.main-content {
    padding-top: 70px;
    padding-bottom: 80px;
}

.home-footer {
    height: 60px;
    padding: 20px;
    text-align: center;
    font-size: 0.9em;
    color: #555;
    background-color: #f8f8f8;
    border-top: 1px solid #ddd;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
}