/* Name : Potluri Shravani */
/* Student Id : 1002129834 */
/* Font Color : white */

.messages-page {
    display: flex;
    gap: 20px;
    padding: 20px;
}

.contacts h2,
.chat h2 {
    margin-bottom: 20px;
}

.contacts {
    width: 200px;
    border-right: 1px solid #ccc;
    overflow-y: auto;
    background-color: #f2f2f2;
}

.received {
    background-color: #f2f2f2;
    align-self: flex-start;
}

.sent {
    background-color: #4ea688;
    color: white;
    align-self: flex-end;
    color: black
}

.contact {
    padding: 15px 20px;
    cursor: pointer;
    border-bottom: 1px solid #ccc;
    background-color: #f2f2f2;
    margin: 0;
}

.chat {
    flex: 3;
    background-color: #f5f5f5;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
}

.messages {
    flex: 1;
    overflow-y: auto;
    margin-bottom: 20px;
}

.message {
    margin-bottom: 10px;
    background-color: #e0e0e0;
    padding: 10px;
    border-radius: 5px;
}

.input-area {
    display: flex;
    gap: 10px;
}

.input-area input {
    flex: 1;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
}

.input-area button {
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    background-color: #005a8d;
    color: white;
    cursor: pointer;
    margin-top: 0px !important;
}

.input-area button:hover {
    background-color: #0056b3;
}
.selected {
    background-color: #005a8d;
    color: white;
    cursor: pointer; /* Optionally change cursor to indicate interactivity */
  }