/* Name: Rudraraju, DileepVarma | Student ID: 1002057318 */
/* Font size - 18px and Font color - #005a8d, #333 */

.admin-dashboard-container {
    background-color: #f5f5f5;
    padding: 20px;
}
.admin-dashboard-container h1 {
   display: flex;
   justify-content: center;
   color: #005a8d;
}
.user-management {
    margin-top: 20px;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    max-width: 1000px;
    margin: 20px auto;
}

.user-management h2 {
    color: #005a8d;
    margin-bottom: 20px;
}

.user-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
}

.user-table th,
.user-table td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: left;
    color: #333;
    font-size: 18px;
}

.user-table th {
    background-color: #f4f4f4;
}

.user-table td {
    background-color: #fdfdfd;
}

.user-table button {
    padding: 5px 10px;
    margin-right: 5px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.user-table button:hover {
    opacity: 0.8;
}

.user-table .activate-btn {
    background-color: #4CAF50;
    color: white;
}

.user-table .deactivate-btn {
    background-color: #f44336;
    color: white;
}

@media (max-width: 768px) {
    .user-management {
        padding: 10px;
    }

    .user-table th,
    .user-table td {
        padding: 8px;
    }

    .user-table button {
        padding: 4px 8px;
        font-size: 14px;
    }
}

.admin-dashboard-container {
    background-color: #f5f5f5;
    padding-top: 70px;
    min-height: 100vh;
}

.admin-dashboard-section {
    background-color: #fff;
    margin: 20px auto;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    max-width: 1000px;
}

.admin-dashboard-section h2 {
    color: #333;
    margin-bottom: 20px;
}

.system-configuration {
    margin-top: 20px;
}

.system-configuration .config-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.admin-button {
    background-color: #005a8d;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.admin-button:hover {
    background-color: #0056b3;
}

@media (max-width: 768px) {
    .admin-dashboard-section {
        margin: 20px;
    }
}

.system-configuration {
    margin-top: 20px;
}

.system-configuration .config-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.system-configuration .config-item label {
    flex: 1;
}

.system-configuration .config-item input[type="checkbox"] {
    margin-left: 10px;
}

.data-reporting {
    margin-top: 20px;
}

.data-reporting .report-item {
    margin-bottom: 15px;
    padding: 10px;
    background-color: #f9f9f9;
    border-radius: 5px;
}

.data-reporting .report-item h3 {
    margin: 0 0 5px 0;
    color: #0056b3;
}

.data-reporting .report-item p {
    margin: 0;
}

.provider-profile-management {
    margin-top: 20px;
}

.provider-profile-management .profile-item {
    margin-bottom: 15px;
    padding: 10px;
    background-color: #f9f9f9;
    border-radius: 5px;
}

.provider-profile-management .profile-item h3 {
    margin: 0 0 5px 0;
    color: #0056b3;
}

.provider-profile-management .profile-item p {
    margin: 0;
}

.user-management {
    margin-top: 20px;
}

.user-management .user-item {
    margin-bottom: 15px;
    padding: 10px;
    background-color: #f9f9f9;
    border-radius: 5px;
}

.user-management .user-item h3 {
    margin: 0 0 5px 0;
    color: #0056b3;
}

.user-management .user-item p {
    margin: 0;
}

.admin-dashboard-nav {
    display: flex;
    justify-content: center;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 10px 0;
}

.admin-dashboard-nav button {
    background-color: #5e94b3;
    border: 1px solid #176da0;
    padding: 10px 20px;
    margin: 0 5px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s, color 0.3s;
}

.admin-dashboard-nav button:hover {
    background-color: #5e94b3;
}

.admin-dashboard-nav button.active {
    background-color: #1e6995;
    color: white;
    border-color: #176da0;
}

.admin-dashboard-main {
    padding: 20px;
    max-width: 1000px;
    margin: 20px auto;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.provider-profile-management {
    margin-top: 20px;
}

.provider-profile-item {
    background-color: #f9f9f9;
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.provider-profile-item h3 {
    color: #0056b3;
    margin: 0 0 10px 0;
}

.provider-profile-item p {
    margin: 5px 0;
}

.provider-profile-actions {
    margin-top: 10px;
}

.provider-profile-actions button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 10px;
    transition: background-color 0.2s;
}

.provider-profile-actions button:hover {
    background-color: #43a047;
}

.provider-profile-actions button.edit {
    background-color: #43a047;
}

.provider-profile-actions button.edit:hover {
    background-color: #FFB300;
}

.provider-profile-actions button.delete {
    background-color: #f44336;
}

.provider-profile-actions button.delete:hover {
    background-color: #e53935;
}

.provider-profile-management .provider-profile-item {
    background-color: #f9f9f9;
    border: 1px solid #eaeaea;
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 15px;
}

.provider-profile-management .provider-profile-item h3 {
    margin-top: 0;
    color: #0056b3;
}

.provider-profile-management .provider-profile-item p {
    margin: 5px 0;
}

.provider-profile-management button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 10px;
    transition: background-color 0.2s;
}

.provider-profile-management button:hover {
    background-color: #43a047;
}

.provider-profile-management .button-delete {
    background-color: #f44336;
}

.provider-profile-management .button-delete:hover {
    background-color: #d32f2f;
}
span {
    color: #cbd6dc;
}