.maintenance-mode {
    max-width: 400px;
    margin: 20px auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #fff;
}

.maintenance-mode h2 {
    color: #333;
    font-family: Arial, sans-serif;
    text-align: center;
}

.status-control {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
}

.status-control label {
    margin-right: 10px;
}

.status-control select {
    flex-grow: 1;
    margin-right: 10px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f8f8f8;
}

.status-control button {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    background-color: #4CAF50;
    color: white;
    cursor: pointer;
}

.status-control button:hover {
    background-color: #45a049;
}

/* Styles for the tabs container */
.react-tabs {
    font-family: Arial, sans-serif;
}

/* Style for the tab list */
.react-tabs__tab-list {
    border-bottom: 2px solid #ccc;
    padding-left: 0;
    display: flex;
    list-style-type: none;
    margin: 0;
}

/* Style for each tab */
.react-tabs__tab {
    padding: 10px 20px;
    cursor: pointer;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
    margin-right: 5px;
}

/* Style for the active tab */
.react-tabs__tab--selected {
    background-color: #fff;
    border-bottom: none;
    position: relative;
    top: 1px;
}

/* Style for the tab panel */
.react-tabs__tab-panel {
    padding: 20px;
    border: 1px solid #ccc;
    margin-top: -1px;
    background-color: #fff;
}

/* Button styles */
button {
    background-color: #4CAF50;
    /* Green */
    color: white;
    padding: 10px 20px;
    margin: 10px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

button:hover {
    background-color: #45a049;
}

h2 {
    color: #333;
}

h3 {
    margin-top: 0;
    color: #555;
}

p {
    color: #666;
    font-size: 16px;
    line-height: 1.5;
}

div.report-item {
    margin-bottom: 10px;
    padding: 10px;
    border-left: 5px solid #4CAF50;
    background-color: #f4f4f4;
}