/* Name : Ram Shriyukta | Student Id : 1002152017 */
/* Font Size: 16px, Font Color: #005a8d, white, black */
.healthcare-admin-dashboard {
    background-color: #f5f5f5;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
}

.healthcare-admin-dashboard h1 {
   display: flex;
   justify-content: center;
   color: #005a8d;
}

.facility-list,
.staff-list {
    list-style-type: none;
    padding: 0;
}

.facility-list li,
.staff-list li {
    background: #ffffff;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

button {
    padding: 5px 10px;
    border-radius: 4px;
    border: none;
    background-color: #4CAF50;
    color: white;
    cursor: pointer;
}

button:hover {
    background-color: #45a049;
}

.facility-management {
    max-width: 1000px;
    margin: 30px auto 0 !important;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
}

.facility-management h2 {
    text-align: center;
    margin-bottom: 20px;
}

.add-facility-btn {
    display: block;
    margin: 0 auto 20px;
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.add-facility-btn:hover {
    background-color: #45a049;
}

.facility-list {
    list-style-type: none;
    padding: 0;
}

.facility-item {
    background-color: white;
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.facility-details h3 {
    margin-top: 0;
}

.facility-actions button {
    padding: 5px 10px;
    margin-left: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.edit-btn {
    background-color: #197ab3;
    color: black;
}
.edit-btn:hover {
    background-color: #3e8ebc;
    color: black;
}

.status-btn {
    background-color: #F44336;
    color: white;
}

.status-btn.activate {
    background-color: #4CAF50;
}

.status-btn:hover {
    opacity: 0.9;
}

.staff-coordination {
    max-width: 1000px;
    margin: 30px auto 0;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
}

.staff-coordination h2 {
    text-align: center;
    margin-bottom: 20px;
}

.add-staff-btn {
    display: block;
    margin: 0 auto 20px;
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.add-staff-btn:hover {
    background-color: #45a049;
}

.staff-list {
    list-style-type: none;
    padding: 0;
}

.staff-item {
    background-color: white;
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.staff-details h3 {
    margin-top: 0;
}

.staff-actions button {
    padding: 5px 10px;
    margin-left: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.edit-btn {
    background-color: #197ab3;
    color: black;
}

.remove-btn {
    background-color: #F44336;
    color: white;
}

.remove-btn:hover {
    opacity: 0.9;
}

.compliance-oversight {
    max-width: 1000px;
    margin: 30px auto 0;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
}

.compliance-oversight h2 {
    text-align: center;
    margin-bottom: 20px;
}

.add-compliance-btn {
    display: block;
    margin: 0 auto 20px;
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.add-compliance-btn:hover {
    background-color: #45a049;
}

.compliance-list {
    list-style-type: none;
    padding: 0;
}

.compliance-item {
    background-color: white;
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.compliance-details h3 {
    margin-top: 0;
}

.compliance-actions button {
    padding: 5px 10px;
    margin-left: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.edit-btn {
    background-color: #197ab3;
    color: black;
}

.update-status-btn {
    background-color: #4CAF50;
    color: white;
}

.update-status-btn:hover {
    opacity: 0.9;
}

.incident-response {
    max-width: 1000px;
    margin: 30px auto 0;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
}

.incident-response h2 {
    text-align: center;
    margin-bottom: 20px;
}

.add-incident-btn {
    display: block;
    margin: 0 auto 20px;
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.add-incident-btn:hover {
    background-color: #45a049;
}

.incident-list {
    list-style-type: none;
    padding: 0;
}

.incident-item {
    background-color: white;
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.incident-details h3 {
    margin-top: 0;
}

.incident-actions button {
    padding: 5px 10px;
    margin-left: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.edit-btn {
    background-color: #197ab3;
    color: black;
}

.status-btn {
    background-color: #F44336;
    color: white;
}

.status-btn:hover {
    opacity: 0.9;
}

.dashboard-tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.dashboard-tabs button {
    background-color: #5e94b3;
    border: 1px solid #176da0;
    padding: 10px 20px;
    margin: 0 5px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s, color 0.3s;
}

.dashboard-tabs button:hover {
    background-color: #5e94b3;
}

.dashboard-tabs button.active {
    background-color: #1e6995;
    color: white;
    border-color: #176da0;
}

 .modal-content-healthcareadmin {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 20px;
    width: 40%;
    height: 40%;
} 

.modal-content-healthcareadmin-compliance {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 20px;
    width: 40%;
    height: 25%;
}