/* Name : Potluri Shravani */
/* Student Id : 1002129834 */
/*  Font Size : 14px, 16px, Font Color : #005a8d, white, #666, #333 */

.community-interaction-container {
    background: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
    margin: 20px auto;
    max-width: 1000px;
}

.community-interaction-container h2 {
    margin-bottom: 20px;
    color: #005a8d;
}

.new-post-textarea {
    width: 98%;
    height: 100px;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.submit-post-button {
    width: 100%;
    padding: 10px;
    background-color: #005a8d;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 20px;
}

.submit-post-button:hover {
    background-color: #0056b3;
}

.posts-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.post {
    background: #ffffff;
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.post h3 {
    margin: 0 0 10px 0;
    color: #005a8d;
}

.timestamp {
    font-size: 0.9em;
    color: #666;
}

.content {
    font-size: 1em;
    color: #333;
}
.post-actions {
    display: flex;
    justify-content: flex-start;
    margin-top: 10px;
}
.update_button {
    background-color: #005a8d;
    color: white;
    margin-right: 10px;
}
.update_button:hover {
    background-color: #0056b3;
}
.delete_button {
    background-color: rgb(194, 69, 69);
    color: white;
}
.delete_button:hover {
    background-color: rgb(161, 49, 49);
}
.close_button {
    background: #ccc;
}
.close_button:hover {
    background: #999;
}

.post-actions button:last-child {
    margin-right: 0;

}
.error {
    color: red;
}
.modal-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 20px;
}

.modal-buttons button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-size: 1em;
    cursor: pointer;
}

.modal-buttons button:first-child {
    background: #ccc;
}

.modal-buttons button:last-child {
    background: #005a8d;
    color: white;
}