/* Name : Potluri Shravani */
/* Student Id : 1002129834 */
/* Font Size : 16px, Font Color : #005a8d, white, lightsteelblue, black */

/* Patient Dashboard */
.patient-dashboard-container {
    background-color: #f5f5f5;
    padding: 20px;
}

.patient-dashboard-container h1 {
    display: flex;
    justify-content: center;
    color: #005a8d;
}

.patient-dashboard-nav {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    max-width: 1000px;
    margin: 0 auto;
}

@media (max-width: 768px) {
    .patient-dashboard-nav {
        flex-direction: column;
    }
}

.patient-dashboard-nav button {
    background-color: #5e94b3;
    border: 1px solid #176da0;
    padding: 10px 20px;
    margin: 0 5px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s, color 0.3s;
}

@media (max-width: 768px) {
    .patient-dashboard-nav button {
        margin-bottom: 20px;
    }
}

.patient-dashboard-nav button:hover {
    background-color: #5e94b3;
}

.patient-dashboard-nav button.active {
    background-color: #1e6995;
    color: white;
    border-color: #176da0;
}

/* Patient Health Records Component CSS */
.personal-health-records-component {
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
    max-width: 1000px;
    margin: 30px auto 0;
}
.personal-health-records-component h2 {
    margin-bottom: 10px;
    padding-bottom: 20px;
    color: #005a8d;
}

@media (max-width: 768px) {
    .personal-health-records-component {
        margin: auto;
    }
}

.button-add-record {
    position: absolute;
    top: 10px;
    right: 90px;
    margin-right: 20px;
    margin-top: 30px;
    background-color: #0b550de4;
}

@media (max-width: 600px) {
    .button-add-record {
        position: static;
        margin: 20px auto;
        width: 100%;
        text-align: center;
    }
}

.edit-record-button {
    position: absolute;
    top: 10px;
    right: 10px;
    margin-right: 10px;
    margin-top: 30px;
    background-color: #005a8d;
}

.edit-record-button:hover {
    background-color: #0056b3;
}

@media (max-width: 600px) {
    .edit-record-button {
        position: static;
        margin: 20px auto;
        width: 100%;
    }
}

.health-record-section {
    background-color: #f8f9fa;
    border-radius: 8px;
    padding: 0px 20px 20px 20px;
}

.health-record-section h3 {
    margin-bottom: 10px;
}

.health-record-section ul {
    list-style-type: none;
    padding: 0;
}

.health-record-section li {
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 5px;
}

.health-record-section input {
    display: block;
    margin-bottom: 10px;
    padding: 5px;
    font-size: 1em;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    padding: 10px;
    margin-left: 0px !important;
    width: 50%;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 20px;
    width: 40%;
    height: 30%;
}

.modal-edit-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-edit-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 20px;
    width: 40%;
    height: 70%;
}

.modal-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.modal-form label {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.modal-form input {
    flex-grow: 1;
    margin-left: 60px;
}

.modal-form textarea {
    flex-grow: 1;
    margin-left: 10px;

}

.modal-form input,
.modal-form textarea {
    padding: 10px;
    font-size: 1em;
    border-radius: 4px;
    border: 1px solid #ccc;
}

.modal-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 20px;
}

.modal-buttons button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-size: 1em;
    cursor: pointer;
}

.modal-buttons button:first-child {
    background: #ccc;
}

.modal-buttons button:last-child {
    background: #005a8d;
    color: white;
}

/* Symptom Checker Component CSS */
.symptom-checker-component {
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
    max-width: 1000px;
    margin: 30px auto 0;
}

.symptom-checker-component h2 {
    margin-bottom: 10px;
    padding-bottom: 20px;
    color: #005a8d;
}

.symptom-checker-component p {
    margin-bottom: 20px;
}

.symptom-checker-component input[type="text"] {
    width: 98%;
    padding: 10px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    margin-bottom: 10px;
}

.button-check-symptoms {
    background-color: #005a8d;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    display: block;
    width: 100%;
    font-size: 16px;
}

.symptom-dropdown {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    font-size: 16px;
}

.button-check-symptoms:hover {
    background-color: #0056b3;
}

.appointment-actions {
    margin-top: 10px;
}

.button-reschedule,
.button-cancel,
.button-view-record {
    background-color: #005a8d;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 10px;
}

.button-cancel {
    background-color: #c9453c;
}

.button-view-record {
    background-color: #4CAF50;
}

.button-reschedule:hover,
.button-view-record:hover {
    background-color: #2b71bc;
}

.button-cancel:hover {
    background-color: #e65353;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
    width: 100%;
}

.react-datepicker__input-container input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.react-datepicker-wrapper {
    margin-bottom: 20px;
}

.react-datepicker__input-container input {
    width: 95%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
}

button {
    font-size: 16px;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
    margin-top: 10px;
}

button.close-modal {
    background-color: #f44336;
    color: white;
}

button.close-modal:hover {
    background-color: #d32f2f;
}

button.reschedule-modal {
    background-color: #4CAF50;
    color: white;
}

button.reschedule-modal:hover {
    background-color: #45a049;
}

.button-create-schedule {
    position: absolute;
    top: 20px;
    right: 20px;
    background-color: #0b550de4;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px;
}

@media (max-width: 600px) {
    .button-create-schedule {
        position: static;
        margin: 20px auto;
        width: 100%;
        text-align: center;
    }
}

/* Patients Appointments Component CSS */
.patient-appointments-component {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
    max-width: 1000px;
    margin: 30px auto 0;
}

.patient-appointments-component h2 {
    margin-bottom: 10px;
    padding-bottom: 20px;
    color: #005a8d;
}

.patient-appointments-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 20px;
    width: 100%;
    max-width: 1000px;
    margin: 20px auto;
}

/* For screens smaller than 600px */
@media (max-width: 600px) {
    .patient-appointments-grid {
        grid-template-columns: 1fr;
    }
}

/* For screens between 600px and 900px */
@media (min-width: 600px) and (max-width: 900px) {
    .patient-appointments-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

/* For screens larger than 900px */
@media (min-width: 900px) {
    .patient-appointments-grid {
        grid-template-columns: repeat(3, 1fr);
    }
}

.appointment-card {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0px 10px 20px -10px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease-in-out;
    min-width: 292px;
}

.appointment-card-hcp {
    background-color: #fff;
    border-radius: 10px;
    /* padding: 20px; */
    box-shadow: 0px 10px 20px -10px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease-in-out;
    min-width: 292px;
}

.appointment-card:hover {
    transform: scale(1.03);
}

.reschedule-modal-content {
    color: 'lightsteelblue';
    top: '50%';
    left: '50%';
    right: 'auto';
    bottom: 'auto';
    margin-right: '-50%';
    transform: 'translate(-50%, -50%)';
    width: '30%';
    height: '50%';
}

.reschedule-modal-overlay {
    background-color: 'rgba(0, 0, 0, 0.5)';
}

.reschedule-modal-buttons {
    position: absolute;
    bottom: 20px;
    right: 10px;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

.reschedule-modal-buttons button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-size: 1em;
    cursor: pointer;
}

.reschedule-modal-buttons button:first-child {
    background: #ccc;
}

.reschedule-modal-buttons button:last-child {
    background: #005a8d;
    color: white;
}

.schedule-modal-form {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    padding: 20px;
}

.schedule-modal-form label {
    flex: 1;
    align-items: center;
    color: black;
}

.schedule-modal-form input {
    flex-grow: 2;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1em;

}

.schedule-modal-form .react-datepicker-wrapper {
    flex-grow: 2;
    margin-left: 50px;

}

.schedule-modal-form input {
    flex-grow: 2;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1em;
}

/* Patients E-Prescriptions Component CSS */
.prescriptions-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
    max-width: 1000px;
    margin: 30px auto 0;
}
.prescriptions-container h2 {
    margin-bottom: 10px;
    padding-bottom: 20px;
    color: #005a8d;
}

.button-create-prescription {
    margin-left: auto;
    background-color: #0b550de4;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px;
}

.prescriptions-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 20px;
    width: 100%;
    max-width: 1000px;
    margin: 20px auto;
}

/* For screens smaller than 600px */
@media (max-width: 600px) {
    .prescriptions-grid {
        grid-template-columns: 1fr;
    }
}

/* For screens between 600px and 900px */
@media (min-width: 600px) and (max-width: 900px) {
    .prescriptions-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

/* For screens larger than 900px */
@media (min-width: 900px) {
    .prescriptions-grid {
        grid-template-columns: repeat(3, 1fr);
    }
}

.prescription-card {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0px 10px 20px -10px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease-in-out;
    min-width: 250px;
}

.prescription-card:hover {
    transform: scale(1.03);
}

.medication-name {
    color: #005a8d;
    margin-bottom: 10px;
}

.prescription-info p {
    margin: 5px 0;
}

.prescription-actions {
    margin-top: 20px;
    text-align: center;
}

.button-view {
    background-color: #005a8d;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-right: 5px;
}

.button-view:hover {
    background-color: #0056b3;
}

.card {
    border: 1px solid #ccc;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    padding: 20px;
    margin: 20px 0;
}

.card h2 {
    margin-top: 0;
}

.card ul {
    list-style-type: none;
    padding: 0;
}

.card li {
    margin-bottom: 10px;
}

.close_button {
    background: #ccc;
}

.close_button:hover {
    background: #999;
}

.update_button {
    background-color: #005a8d;
    color: white;
    margin-right: 10px;
}

.update_button:hover {
    background-color: #0056b3;
}

.button-container {
    display: flex;
    justify-content: flex-end;
}

.reminder-alert {
    margin: 10px 0;
    padding: 20px;
    background-color: #efe1bb;
    /* Light red background */
    color: #333;
    /* Dark text for good contrast */
    border-left: 5px solid #e3dc66;
    /* Bold red left border */
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.reminder-alert h4 {
    margin-top: 0;
    color: #f8f363;
}

.reminder-alert p {
    margin: 5px 0;
}