/* Name : Divya Boggavarapu */
/* Student Id : 1002086719 */
/*  Font Size : 14px, 16px, Font Color : #005a8d, white */
.messages-component {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-top: 20px;
}

.messages-component h2 {
    color: #333;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    margin-bottom: 20px;
}

.messages-list {
    list-style-type: none;
    padding: 0;
}

.message-item {
    border: 1px solid #eee;
    border-radius: 4px;
    padding: 15px;
    margin-bottom: 10px;
}

.message-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.message-header h3 {
    color: #0056b3;
    margin: 0;
}

.message-date {
    font-size: 0.9em;
    color: #666;
}

.message-content {
    margin-top: 10px;
}

.message-actions {
    margin-top: 10px;
}

.button-read,
.button-reply {
    background-color: #005a8d;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 10px;
    transition: background-color 0.2s;
}

.button-reply {
    background-color:#005a8d;
}

.button-read:hover,
.button-reply:hover {
    opacity: 0.9;
}