/* Name : Rudraraju, DileepVarma | Student ID: 1002057318 */
/* Name : Boggavarapu Divya | Student Id : 1002086719 */
/* Name : Arepally Rohith | Student Id: 1002156380 */
/* Name : Potluri Shravani | Student Id : 1002129834 */
/* Name : Ram Shriyukta | Student Id : 1002152017 */
/* Font Size : 14px, Font Color : #005a8d */
.features-container {
    padding: 20px;
    padding-top: 10px;
    background-color: #f5f5f5;
    margin-top: 1px;
    background-image: url("../images/services3.webp");
    height: 90vh;
}

.features-title {
    text-align: center;
    color: #f0f4f7;
    margin-bottom: 60px;
}

.feature-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.feature-item {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 10px;
    padding: 10px;
    width: 30%;
    min-width: 300px;
}

.feature-item h2 {
    color: #005a8d;
    margin-bottom: 15px;
    margin-top: 5px;
}

.feature-item ul {
    list-style-type: none;
    padding: 0;
}

.feature-item li {
    margin-bottom: 10px;
    font-size: 14px;
}