/* Name : Rudraraju, DileepVarma | Student ID: 1002057318 */
/* Name : Boggavarapu Divya | Student Id : 1002086719 */
/* Name : Arepally Rohith | Student Id: 1002156380 */
/* Name : Potluri Shravani | Student Id : 1002129834 */
/* Name : Ram Shriyukta | Student Id : 1002152017 */
/* Font Color : White, #005a8d, #333, #cbd6dc  */

.home-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Arial', sans-serif;
}

.home-header {
    text-align: center;
    padding: 50px 20px;
    background: #003553;
    color: white;
}

.home-section {
    display: flex;
    margin: 40px 0;
}

.home-section.reverse {
    flex-direction: row-reverse;
}

.home-text {
    flex: 1;
    max-width: 600px;
    padding: 40px 40px;
}

h2 {
    color: #005a8d;
    margin-bottom: 10px;
}

p {
    color: #333;
    line-height: 1.6;
}

span {
    color:#cbd6dc
}


/* Add responsive design */
@media (max-width: 768px) {

    .home-section,
    .home-section.reverse {
        flex-direction: column;
        text-align: center;
    }

    .home-text,
    .lottie {
        padding: 0;
        margin-bottom: 20px;
    }
}