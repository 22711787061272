.reset-password-form {
    width: 300px;
    margin: 50px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;

}

.reset-password-form h2 {
    text-align: center;

}

.reset-password-form form label {
    display: block;
    margin-bottom: 10px;
    margin-right: 10px;

}

.reset-password-form form input {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
}


.error {
    color: red;
    text-align: center;
}