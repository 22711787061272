/* Name : Divya Boggavarapu */
/* Student Id : 1002086719 */
/*  Font Size : 14px, 16px, Font Color : #005a8d, white */
.appointments-component {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-top: 20px;
}

.appointments-component h2 {
    color: #333;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    margin-bottom: 20px;
}

.appointments-list {
    list-style-type: none;
    padding: 0;
}

.appointment-item {
    margin-bottom: 15px;
    padding: 10px;
    border: 1px solid #eee;
    border-radius: 4px;
}

.appointment-details h3 {
    margin: 0 0 10px 0;
    color: #0056b3;
}

.appointment-details p {
    margin: 5px 0;
}

.appointment-actions {
    margin-top: 10px;
}

.button-reschedule,
.button-cancel,
.button-view-record {
    background-color: #005a8d;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 10px;
}

.button-cancel {
    background-color: #c9453c;
}

.button-view-record {
    background-color: #4CAF50;
}

.button-reschedule:hover,
.button-cancel:hover,
.button-view-record:hover {
    opacity: 0.9;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
    width: 100%;
}

.react-datepicker__input-container input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.modal-header {
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    margin-bottom: 20px;
}

.modal-header h2 {
    margin: 0;
}

.modal-body {
    margin-bottom: 20px;
}

.modal-footer {
    text-align: right;
}

.modal-footer button {
    background-color: #005a8d;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.modal-footer button:hover {
    background-color: #45a049;
}

.modal-close-button {
    background-color: #f44336;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    float: right;
}

.modal-close-button:hover {
    background-color: #d32f2f;
}