/* Name : Divya Boggavarapu */
/* Student Id : 1002086719 */
/*  Font Size : 14px, 16px, Font Color : #005a8d, white */
.component-container {
    max-width: 1000px;
    margin: 0 auto;
    background-color: #f8f9fa;
    border-radius: 8px;
    padding: 20px;
    
}

.dashboard-content {
    text-align: center;
}

.provider-directory-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    background: #fff;
    padding-top: 100px;
}

.provider-directory-container h1 {
    text-align: center;
    color: #005a8d;
    margin-bottom: 30px;
}

.search-and-filter {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    gap: 10px;
    margin-bottom: 20px;
}

.search-and-filter input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
}

.provider-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
}

.provider-card {
    background: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.provider-card h3 {
    color: #005a8d;
    margin-bottom: 10px;
}

.provider-card p {
    margin-bottom: 5px;
    line-height: 1.5;
}

@media (max-width: 768px) {
    .search-and-filter {
        grid-template-columns: 1fr;
    }
}

.dashboard-tabs {
    /* text-align: center;
    padding-top: 80px;
    background: #f7f7f7;
    padding-bottom: 10px; */
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    max-width: 1100px;
    margin: 0 auto;
}

.dashboard-tabs button {
    background-color: #5e94b3;
    border: 1px solid #176da0;
    padding: 10px 20px;
    margin: 0 5px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s, color 0.3s;
}

.dashboard-tabs button:hover {
    background-color: #5e94b3;
}

.dashboard-tabs button.active {
    background-color: #1e6995;
    color: white;
    border-color: #176da0;
}

.dashboard-tabs button:focus {
    outline: none;
}

.dashboard-tabs button:active {
    transform: scale(0.98);
}
.healthcare-provider-dashboard{
    /* text-align: center; */
    color: #005a8d;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}