/* Name : Rudraraju, DileepVarma | Student ID: 1002057318 */
/* Name : Boggavarapu Divya | Student Id : 1002086719 */
/* Name : Arepally Rohith | Student Id: 1002156380 */
/* Name : Potluri Shravani | Student Id : 1002129834 */
/* Name : Ram Shriyukta | Student Id : 1002152017 */
/* Font Color : white */

.registration-container {
    max-width: 400px;
    margin: 50px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    
}

.registration-container h2 {
    text-align: center;
    margin-bottom: 30px;
}

.form-group {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.form-group label {
    flex: 1;
    width: 100%;
    margin-bottom: 5px;
    text-align: left;

}

.form-group input,
.form-group select {
    flex: 2;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
}


.form-actions button {
    width: calc(100% - 5px);
    padding: 20px;
    border: none;
    background-color: #2a9d8f;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
    margin-right: 10px;
}

.form-actions button:hover {
    background-color: #22867c;
}

.form-actions button:not(:last-child) {
    margin-right: 10px;
}

.form-actions button:not(:first-child) {
    margin-top: 10px;
}


.form-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.form-group label {
    margin-right: 20px;
}

.form-group input,
.form-group select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
}