/* Name : Divya Boggavarapu */
/* Student Id : 1002086719 */
/*  Font Size : 14px, 16px, Font Color : #005a8d, white */
.patient-records-component {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-top: 20px;
}

.patient-records-component h2 {
    color: #333;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    margin-bottom: 20px;
}

.patient-records-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
}

.patient-record-item {
    border: 1px solid #eee;
    border-radius: 4px;
    padding: 15px;
}

.patient-record-item h3 {
    margin: 0 0 10px 0;
    color: #0056b3;
}

.patient-record-item p {
    margin: 5px 0;
}

.button-edit-record {
    background-color: #005a8d;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.button-edit-record:hover {
    opacity: 0.9;
}
.modal {
    display: block; /* Make the modal visible */
    position: fixed; /* Position the modal */
    z-index: 1000; /* Ensure the modal is on top of other elements */
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
}

.modal-content {
    background-color: #fefefe;
    margin: 15% auto; /* Center the modal vertically and horizontally */
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    max-width: 600px; /* Limit the maximum width of the modal */
    border-radius: 8px;
    position: relative; /* Ensure the close button stays relative to the modal content */
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    cursor: pointer;
}

.close-button:hover {
    color: red; /* Change color on hover */
}

.button-save-record {
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
}

.button-save-record:hover {
    background-color: #45a049;
}

label {
    display: block;
    margin-bottom: 10px;
}

input[type="text"] {
    width: 100%;
    padding: 8px;
    margin-top: 6px;
    margin-bottom: 16px;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

input[type="text"]:focus {
    outline: none;
    border-color: #4CAF50; /* Add focus style */
}