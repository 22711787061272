/* Name : Arepally Rohith */
/* Student Id: 1002156380 */
/* Font Size: 24px, 16px, Font Color: #333333, #ffffff, #666666 */
    
.pharmacist-dashboard {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #f4f4f4;
}

.dashboard-tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.dashboard-tabs button {
    background-color: #ffffff;
    border: none;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    margin: 0 10px;
    padding: 10px 20px;
    font-size: 16px;
    color: #333333;
    outline: none;
    transition: background-color 0.3s;
}

.dashboard-tabs button.active {
    background-color: #007bff;
    color: #ffffff;
}

.dashboard-content {
    width: 100%;
    max-width: 900px;
}

.component-container {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    padding: 20px;
}

.component-container h2 {
    color: #333333;
    font-size: 24px;
    margin-bottom: 15px;
    text-align: center;
}

.component-container ul {
    list-style-type: none;
    padding: 0;
}

.component-container li {
    background-color: #f9f9f9;
    border: 1px solid #eaeaea;
    border-radius: 5px;
    margin-bottom: 10px;
    padding: 10px;
}

.component-container p {
    color: #666666;
    font-size: 16px;
    margin: 5px 0;
}

@media (min-width: 768px) {
    .pharmacist-dashboard {
        padding: 40px;
    }

    .dashboard-tabs {
        margin-bottom: 30px;
    }

    .dashboard-content {
        max-width: 1200px;
    }
}