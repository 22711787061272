/* Name : Rudraraju, DileepVarma | Student ID: 1002057318 */
/* Name : Boggavarapu Divya | Student Id : 1002086719 */
/* Name : Arepally Rohith | Student Id: 1002156380 */
/* Name : Potluri Shravani | Student Id : 1002129834 */
/* Name : Ram Shriyukta | Student Id : 1002152017 */
/* Font Size : 16px, 18px, Font Color : White  */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px;
    background-color: #005a8d;
    color: white;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 1000;
}

.nav-links {
    display: flex;
    align-items: center;
    gap: 8px;
}

.nav-links a {
    color: white;
    text-decoration: none;
    padding: 8px 16px;
    border-radius: 4px;
    transition: background-color 0.3s, color 0.3s;
}

.nav-links.open {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
}

.navbar a:hover,
.navbar button:hover {
    background-color: #004671;
}

.menu-icon {
    display: none;
    cursor: pointer;
}

.menu-icon .bar {
    display: block;
    width: 25px;
    height: 3px;
    background-color: white;
    margin: 5px 0;
    transition: 0.4s;
}

.menu-icon .change .bar:nth-child(1) {
    transform: rotate(-45deg) translate(-5px, 6px);
}

.menu-icon .change .bar:nth-child(2) {
    opacity: 0;
}

.menu-icon .change .bar:nth-child(3) {
    transform: rotate(45deg) translate(-5px, -6px);
}

@media (max-width: 768px) {
    .nav-links {
        display: none;
        flex-direction: column;
        width: 100%;
        padding-top: 10px;
    }

    .nav-links.open {
        display: flex;
    }

    .menu-icon {
        display: block;
    }
}